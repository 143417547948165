import { writable } from 'svelte/store';

//Testing-only variable:
export let useMockupData: boolean = false;

//Routing
export let gotoRoute: any;

//Loading Screen State:
export let isLoading = writable(true);

//Theme Toggle State
export let selectedTheme = writable('nomo_light');

//Button disabled and option Select State
export let buttonDisabled = writable(false);
export let optionSelected = writable(1);

//Dropdown/Select-Element States
export let countrySelected = writable(false);
export let genderSelected = writable(false);
export let dateOfBirthSelected = writable(false);

//Personal Data
export let hasPersonalData = writable(false);
export let hasPersonalProofData = writable(false);
export let hasPersonalDataSubmitted = writable(false);
export let idTypeSelected = writable(false);
export let idLanguageSelected = writable(false);
export let hasIdDocumentData = writable(false);
export let hasIdImageBackside = writable(false);
export let isPersonalDataSubmitted = writable(false);
export let isPersonalVerification = writable(false);

//Address Data
export let hasAddressData = writable(false);
export let hasAddressProofData = writable(false);
export let hasAddressDataSubmitted = writable(false);
export let addressProofLanguageSelected = writable(false);
export let isAddressDataSubmitted = writable(false);
export let isAddressVerification = writable(false);

//Document Scan Instructions
export let hasReadInstructions = writable(false);

interface Country {
	id: string;
	name: string;
	iso3166_a2: string;
	dial_code: string;
}

interface ApiStore {
	kycServices: any; // Replace 'any' with a more specific type if the structure is known
	sumsubId: string | null;
	idenfyId: string | null;
	homeInfo: any; // Replace 'any' with a more specific type if the structure is known
	countries: Country[] | null;
}

export let api = writable<ApiStore>({
	kycServices: null,
	sumsubId: null,
	homeInfo: null,
	countries: null,
});

export const kycServiceStatus = writable({
	has_detail: false,
	detail_status: 0,
	detail_error: "",
	has_address: false,
	address_status: 0,
	address_error: "",
	user_details: null
});
