import { writable } from 'svelte/store';
import { browser } from '$app/environment';

export const formData = writable(
	browser && {
		...{
			// selfie
			selfie: '',
			selfie_prefill: '',
			// id scan
			id_front: '',
			id_back: '',
			//nationality
			countryId: '',
			countryCode: '',
			countryName: '',
			// general
			gender: '',
			first_name: '',
			last_name: '',
			date_of_birth: '',
			// latin
			latinFirstName: '',
			latinLastName: '',
			// address
			street_name: '',
			building_name: '',
			building_number: '',
			flat_number: '',
			zipcode: '',
			city: '',
			state: '',
			//Address proof
			address_proof_issued_by: '',
			address_issued_date: '',
			address_proof_language: '',
			//addressCountry
			addressCountryId: '',
			addressCountryName: '',
			addressCountryCode: '',
			// id
			id_type: '',
			idLanguageId: '',
			idLanguageCode: '',
			id_language_name: '',
			address_proof_language_id: '',
			address_proof_language_code: '',
			address_proof_language_name: '',
			// headers
			messengerCode: '',
			ethWalletAddress: '',
			// debit specials
			id_document_number: '',
			idDocumentExpirationDate: '',
			idDocumentCountryId: '',
			idDocumentCountryCode: '',
			idDocumentCountryName: '',
			id_document_issued_by: '',
			id_document_issued_date: '',
			addressDocumentNumber: '',
			addressDocumentExpirationDate: '',
			addressDocumentCountryId: '',
			addressDocumentCountryCode: '',
			addressDocumentCountryName: '',
			address_proof_back: '',
			address_proof_front: '',
			address_proof_front_prefill: '',
			idBackFileId: '',
			id_front_file_id: '',
			id_front_prefill: '',
			id_back_prefill: ''
		},
		...JSON.parse(localStorage.getItem('formData') || '{}'),
	}
);

export const createFormCopyWithNoImages = (form) => {
	const formDataNoPictures = { ...form };
	delete formDataNoPictures['selfie'];
	delete formDataNoPictures['idFront'];
	delete formDataNoPictures['idBack'];
	delete formDataNoPictures['addressFront'];
	delete formDataNoPictures['addressBack'];
	delete formDataNoPictures['selfiePrefill'];
	delete formDataNoPictures['idFrontPrefill'];
	delete formDataNoPictures['idBackPrefill'];
	delete formDataNoPictures['addressFrontPrefill'];
	delete formDataNoPictures['addressBackPrefill'];
	return formDataNoPictures;
};
