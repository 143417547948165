<script lang="ts">
	import { _, waitLocale } from 'svelte-i18n';
	import { onMount } from 'svelte';

	let ready = false

	onMount(async () => {
		await waitLocale()
		ready = true
	})
</script>

{#if ready}
	<div class="loading-screen-container">
		<div class="loading-icon-box">
			<span class="loading loading-spinner text-primary"></span>
		</div>
		<div class="loading-text-box">
			<h2 class="loading-text">{$_('loading.text')}</h2>
		</div>
	</div>
{:else}
	<slot />
{/if}

<style lang="scss">
	@import '$lib/styles/mixins';

	.loading-screen-container {
		@include full-height-width;
		@include flex-center;
		flex-direction: column;

		.loading-icon-box {
			height: 60px;
			margin-bottom: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.loading-text {
			text-transform: uppercase;
			color: theme('colors.neutral');
			justify-content: center;
			font-size: 1rem;
			line-height: 1.25rem;
			letter-spacing: 0.125px;
		}
	}
</style>
